import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {ArrowRight} from '@styled-icons/bootstrap/ArrowRight';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';

function getDayDisplay(dateStr) {
  let day = new Date(dateStr).getDay();
  if (day === 0) {
    return '日';
  } else if (day === 1) {
    return '一';
  } else if (day === 2) {
    return '二';
  } else if (day === 3) {
    return '三';
  } else if (day === 4) {
    return '四';
  } else if (day === 5) {
    return '五';
  } else if (day === 6) {
    return '六';
  }
}

function ProductsSection(props) {
  const {courses, activities} = props;
  const [selectedTab, setSelectedTab] = React.useState(0);

  return (
    <Wrapper>
      <div className="container">
        <div className="tabs-header">
          <div
            className={`tab ${selectedTab === 0 && 'selected'} `}
            onClick={() => setSelectedTab(0)}>
            課程
          </div>
          <div
            className={`tab ${selectedTab === 1 && 'selected'} `}
            onClick={() => setSelectedTab(1)}>
            活動
          </div>
        </div>
        <div className="tabs-content">
          {selectedTab === 0 &&
            courses &&
            courses.map((item, index) => {
              const isSession =
                item.session &&
                Object.keys(item.session).length > 0 &&
                item.session.date;

              return (
                <div
                  className="item"
                  key={`course-${index}`}
                  onClick={() => AppActions.navigate(`/product?id=${item.id}`)}>
                  <div className="date">
                    {isSession
                      ? `${item.session.date} (${getDayDisplay(
                          item.session.date,
                        )})`
                      : '線上自由安排'}
                  </div>
                  <div className="name">{item.name}</div>
                  <ArrowRight size={20} color="#6E2D8C" />
                </div>
              );
            })}

          {selectedTab === 1 &&
            activities &&
            activities.map((item, index) => {
              const isSession =
                item.session &&
                Object.keys(item.session).length > 0 &&
                item.session.date;

              return (
                <div
                  className="item"
                  key={`activity-${index}`}
                  onClick={() => AppActions.navigate(`/product?id=${item.id}`)}>
                  <div className="date">
                    {isSession
                      ? `${item.session.date} (${getDayDisplay(
                          item.session.date,
                        )})`
                      : '線上自由安排'}
                  </div>
                  <div className="name">{item.name}</div>
                  <ArrowRight size={20} color="#6E2D8C" />
                </div>
              );
            })}
          <div className="item fake" />
          <div className="item fake" />
          <div className="item fake" />
        </div>
        <div className="actions">
          <Button
            type="text"
            className="more"
            onClick={() => AppActions.navigate(`/products?sort=-session.date`)}>
            更多課程及活動
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #e5e5e5;
  padding: 60px 40px 45px 40px;
  width: 100%;

  & > .container {
    margin: 0 auto;
    max-width: var(--containerMaxWidth900px);
    width: 100%;

    & > .tabs-header {
      display: flex;

      & > .tab {
        flex: 1;
        cursor: pointer;
        border-bottom: 6px solid #969696;
        padding-bottom: 10px;
        text-align: center;
        font-weight: 600;
        font-size: 1.2rem;

        &.selected {
          border-bottom: 6px solid var(--primaryColor);
        }
      }
    }

    & > .tabs-content {
      min-height: 300px;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      & > .item {
        margin: 10px;
        max-width: 200px;
        width: 100%;
        min-height: 120px;
        cursor: pointer;

        & > .date {
          margin-bottom: 5px;
          font-weight: 600;
          font-size: 1.2rem;
          color: var(--primaryColor);
        }

        & > .name {
          margin-bottom: 15px;
          font-size: 1rem;
        }

        &.fake {
          min-height: 0px;
        }
      }
    }

    & > .actions {
      display: flex;
      justify-content: center;

      & > .more {
        font-weight: 600;
      }
    }
  }
`;

export default ProductsSection;
